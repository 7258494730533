<template>
  <div>
    <div class="mx-auto text-center mt-3 txt">Leistungsmodule</div>
    <template>
      <v-row justify="center">
        <v-expansion-panels class="mt-5" inset>
          <v-expansion-panel v-for="m of allModule" :key="m">
            <v-expansion-panel-header class="font-weight-bold">{{
              m.name
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul class="mb-1">
                <li v-for="l of m.list" :key="l">{{ l }}</li>
              </ul>
              {{ m.desc }}
              <div v-if="m.aufw != {}" class="mt-2">
                <span>{{ m.aufw.txt1 }}</span>
                <ul class="mt-1">
                  <li v-for="l of m.aufw.aList" :key="l">{{ l }}</li>
                </ul>
                <span class="mt-1">{{ m.aufw.txt2 }}</span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allModule: [
        {
          name: 'Einsteiger',
          desc: 'Die E57 Punktwolke kann mit weiterverarbeitender Software (CAD oder ähnliches) frei verwendet werden.',
          list: [
            '3 D Einscannen, erfassen des Geländes / Objekts',
            'Registrierung der Punktwolken',
            'Übergabe einer E57 Punktwolke inkl. des Datenarchives',
          ],
          aufw: {
            txt1: 'Aufwand und Umfang der Arbeiten müssen ermittelt werden. Dazu ist folgende Vorgehensweise empfohlen.',
            txt2: 'Zusammenfassen gilt: Je detaillierter der Aufwand, desto höher die Kosten.',
            aList: [
              'Übermitteln von Fotos des Geländes / Objekts. Dies kann schon die Grundlage für ein Verbindliches Angebot sein.',
              'Sind die Fotos nicht aussagekräftig genug, um den genauen Aufwand des Scannens zu bestimmen muss eine Vorort Begehung vorgenommen werden',
              'Danach kann erst der exakte Scanaufwand belastbar beziffert werden.',
            ],
          },
        },
        {
          name: 'Konstrukt',
          desc: 'Die E57 Punktwolke kann mit weiterverarbeitender Software (CAD oder ähnliches) frei verwendet werden.',
          list: [
            '3 D Einscannen, erfassen des Geländes / Objekts',
            'Registrierung der Punktwolken',
            'Übergabe einer E57 Punktwolke inkl. des Datenarchives',
            'Erstellen einer 2D oder 3D DWG/DXF Zeichnung. Die zu erstellenden Elemente werden bei der Festlegung des Auftrag Aufwandes bestimmt',
          ],
          aufw: {
            txt1: 'Aufwand und Umfang der Arbeiten müssen ermittelt werden. Dazu ist folgende Vorgehensweise empfohlen.',
            txt2: 'Zusammenfassen gilt: Je detaillierter der Aufwand, desto höher die Kosten.',
            aList: [
              'Übermitteln von Fotos des Geländes / Objekts. Dies kann schon die Grundlage für ein Verbindliches Angebot sein.',
              'Sind die Fotos nicht aussagekräftig genug, um den genauen Aufwand des Scannens zu bestimmen muss eine Vorort Begehung vorgenommen werden',
              'Danach kann erst der exakte Scanaufwand belastbar beziffert werden.',
            ],
          },
        },
        {
          name: 'Modellieren',
          desc: 'Die E57 Punktwolke kann mit weiterverarbeitender Software (CAD oder ähnliches) frei verwendet werden.',
          list: [
            '3 D Einscannen, erfassen des Geländes / Objekts',
            'Registrierung der Punktwolken',
            'Übergabe einer E57 Punktwolke inkl. des Datenarchives',
            'Modellierung des erfassten Geländes / Objekts und Ausgabe in einem IFC 3D Modell. Optional können andere 3D Formate angeboten werden. Die zu erstellenden Elemente werden bei der Festlegung des Auftrag Aufwandes bestimmt',
            'Erzeugung von Meshes im Geländebereich',
          ],
          aufw: {
            txt1: 'Aufwand und Umfang der Arbeiten müssen ermittelt werden. Dazu ist folgende Vorgehensweise empfohlen.',
            txt2: 'Zusammenfassen gilt: Je detaillierter der Aufwand, desto höher die Kosten.',
            aList: [
              'Übermitteln von Fotos des Geländes / Objekts. Dies kann schon die Grundlage für ein Verbindliches Angebot sein.',
              'Sind die Fotos nicht aussagekräftig genug, um den genauen Aufwand des Scannens zu bestimmen muss eine Vorort Begehung vorgenommen werden',
              'Danach kann erst der exakte Scanaufwand belastbar beziffert werden.',
            ],
          },
        },
        {
          name: 'Entertainment',
          desc: 'Aus den erfassten Daten wird eine Präsentation ausgearbeitet. Der Umfang kann von 3DRenderings zu animierten Filmen bis zu einem VR Rundgang führen. Für Showanwendungen kann auch eine komplette Licht und Medienshow eingebunden werden. In diesem Modul ist der Aufwand absolut erst nach einem genauen Briefing in ein belastbares Angebot erstellbar.',
          aufw: {},
        },
        {
          name: 'Personen und Kleinobjekte',
          desc: 'Ergänzend bieten wir auch die Möglichkeit Personen und Kleinobjekte einzuscannen. Aus diesen Scans wird ein 3D druckbares Format erzeugt. Diese Dateien können direkt an einem 3D Drucker ausgedruckt werden. Achtung die Genauigkeit liegt nur im Millimeter Bereich. Alle erstellten Daten werden aber an den Kunden Übergeben und können in weiterverarbeitender Software verarbeitet werden',
          aufw: {},
        },
        {
          name: 'Venue realty Capture & Visualisierungs & Pre Production Service',
          desc: 'In diesem Modul erfassen wir Ihre Location mittels 3D Scans. Außerdem erfassen wir alle fix verbauten technischen Geräte wie Scheinwerfer, Lautsprecher, Screens, Beamer oder LED Wände. Diese werden in unsere Ableitungen eingearbeitet und können Kunden als Datenbasis bereitgestellt werden. Die Datenbasis umfasst 3D Lichtpläne, DMX Patch, Trimmhöhen bis hin zum Fertig ausgeführten Wysiwyg File mit dem sofort Pre-programminggestartet werden kann.',
          aufw: {},
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.txt {
  font-size: 300%;
}
</style>
