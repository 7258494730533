import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FC0808',
                secondary: '#35378D',
                third: '9C9691',
            }
        }
    }
});
