<template>
  <div>
    <div class="mx-auto text-center mt-3 txt">Kontakt</div>
    <div>
      <p class="text-center txt3 contact">Anfragen an:</p>
    </div>
    <div class="contact">
      <h1 class="mx-auto text-center mt-5">Adresse:</h1>
      <p class="mx-auto text-center txt2">
        Scheringgasse 3 <br />1140 Wien<br />
        Österreich
      </p>
      <h1 class="mx-auto text-center mt-5">Telefon:</h1>
      <p class="mx-auto text-center txt2">+43 (0) 660 9208388</p>
      <h1 class="mx-auto text-center mt-5">E-Mail:</h1>
      <p class="mx-auto text-center txt2">office@punktwolke.at</p>
    </div>
    <!-- <div class="container">
      <form>
        <label>Name</label>
        <input type="text" v-model="name" name="name" placeholder="Your Name" />
        <label>Email</label>
        <input
          type="email"
          v-model="email"
          name="email"
          placeholder="Your Email"
        />
        <label>Message</label>
        <textarea
          name="message"
          v-model="message"
          cols="30"
          rows="5"
          placeholder="Message"
        >
        </textarea>

        <input type="submit" value="Send" />
      </form>
    </div> -->
  </div>
</template>

<script>
// import emailjs from 'emailjs-com';
export default {
  data() {
    return {
      name: '',
      email: '',
      message: '',
    };
  },
  methods: {
    // sendEmail(e) {
    //   console.log('email');
    //   try {
    //     emailjs.sendForm(
    //       'service_di4j6nr',
    //       'template_aklfmxd',
    //       e.target,
    //       'AY5BaN55KykWTnp4J',
    //       {
    //         name: this.name,
    //         email: this.email,
    //         message: this.message,
    //       }
    //     );
    //   } catch (error) {
    //     console.log({ error });
    //   }
    //   // Reset form field
    //   this.name = '';
    //   this.email = '';
    //   this.message = '';
    // },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  margin-top: 5%;
}
.txt {
  font-size: 350%;
}
.txt2 {
  font-size: 120%;
}
.txt3 {
  font-size: 200%;
}

* {
  box-sizing: border-box;
}

.container {
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
}

label {
  float: left;
}

input[type='text'],
[type='email'],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type='submit'] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type='submit']:hover {
  background-color: #45a049;
}
</style>
