<template>
  <div>
    <div class="mx-auto text-center mt-3 txt">
      Wer steckt hinter den Punktwolken?
    </div>
    <v-img
      src="/img/nw_portrai.jpeg"
      max-height="700px"
      max-width="400px"
      class="mx-auto mt-3"
    ></v-img>
    <div class="mx-auto">
      <p class="text-center mx-auto mt-3 txt2">
        Norbert Wolfsberger arbeitet schon seit 30 Jahren mit Planungs uns
        Visualisierungs Programmen . <br />
        In seiner Tätigkeit als Lighting & Setdesigner steht er oft vor der
        Herausforderung das vorhandene Set (Gebäude, Gelände oder Innenraum) zu
        erfassen, <br />um in seine Visualisierung einbinden zu können. <br />
        Durch diese Notwendigkeit entstand die Begeisterung zur 3D
        Realitätserfassung mit technischen Geräten <br />wie 3D Laserscaner und
        anderen. Die Dienstleistung des Reality Capture (Realitäterfassung)
        biete er nun mit seinem Team an.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.txt {
  font-size: 300%;
}
.txt2 {
  font-size: 120%;
}
</style>
