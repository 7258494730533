<template>
  <!-- Rot: FC0808 -->
  <!-- Blöau: 35378D -->
  <!-- Grau: 9C9691 -->
  <v-app class="back">
    <v-app-bar app color="third" dark>
      <v-img
        src="/img/nw_punktwolke_v03.png"
        class="me-2 it"
        max-height="30px"
        max-width="100px"
        contain
        @click="changeHome"
      ></v-img>
      <!-- <v-btn class="me-2">
        <a href="http://www.nw-design-concept.at/">NW Design concept</a></v-btn
      > -->
      <v-spacer></v-spacer>
      <div class="d-xl-none d-lg-none d-md-flex d-sm-flex d-xs-flex">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
      <div class="d-none d-xl-flex d-lg-flex d-sm-none d-md-none d-xs-none">
        <v-btn to="leistungen" class="me-2" active-class="primary"
          >Punktwolke Module</v-btn
        >
        <v-btn to="wPunktwolke" class="me-2" active-class="primary"
          >Wieso Punktwolke?</v-btn
        >
        <v-btn to="wer" class="me-2" active-class="primary"
          >Wer ist Punktwolke?</v-btn
        >
        <!-- <v-btn to="punktwolke&Mehr" class="me-2" active-class="primary"
          >Punktwolke & mehr</v-btn
        > -->
        <v-btn to="kontakt" class="me-2" active-class="primary">Kontakt</v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute left temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="buttonActive">
          <v-list-item>
            <v-list-item-title
              ><router-link to="leistungen"
                >Punktwolke Module</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link to="wPunktwolke"
                >Wieso Punktwolke?</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              ><router-link to="wer"
                >Wer ist Punktwolke?</router-link
              ></v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title
              ><router-link to="punktwolke&Mehr"
                >Punktwolke & mehr</router-link
              ></v-list-item-title
            >
          </v-list-item> -->
          <v-list-item>
            <v-list-item-title
              ><router-link to="kontakt"
                >Kontakt</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    changeHome() {
      this.$router.replace(`/`);
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
