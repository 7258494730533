import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Kontakt from '../views/Kontakt.vue'
import Leistungen from '../views/Leistungen.vue'
import warumPunktwolke from '../views/warumPunktwolke.vue'
import punktwolkeMore from '../views/PunktwolkeMore.vue'
import Wer from '../views/Wer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt,
  },
  {
    path: '/leistungen',
    name: 'Leistungen',
    component: Leistungen,
  },
  {
    path: '/wPunktwolke',
    name: 'warumPunktwolke',
    component: warumPunktwolke,
  },
  {
    path: '/wer',
    name: 'Wer',
    component: Wer,
  },
  {
    path: '/punktwolke&Mehr',
    name: 'punktwolkeMore',
    component: punktwolkeMore,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
