<template>
  <div>
    <div class="mx-auto text-center mt-3 txt">Warum Punktwolke?</div>
    <v-card
      v-for="c of cards"
      :key="c"
      class="mx-auto mt-5 mb-2"
      max-width="600"
    >
      <v-card-title>{{ c.titel }}</v-card-title>
      <v-img
        :src="`${c.img}`"
        max-height="500px"
        max-width="300px"
        class="mx-auto"
      ></v-img>
      <v-card-text
        >{{ c.desc }}
        <ul class="mt-1 mb-1">
          <li v-for="l of c.list" :key="l">{{ l }}</li>
        </ul>
        {{ c.desc2 }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          titel: 'Punktwolken machen',
          desc: 'Als Punktwolke bezeichnet man das Ergebnis bei der Realitätserfassung durch unterschiedliche technische Geräte. Hier spricht man von 3D Scannern.Beim Abtasten und Erfassender Umgebung werden zur Darstellung der Messungen Punkte erzeugt.Diese ergeben am Ende das Bild der Punktwolke. Diese Scans können auch in Verbindung mit Foto und Wärmebilderfassungen kombiniert werden. Punktwolken helfen bei der Digitalen Erfassung der Realität. Diese Daten kann man in den unterschiedlichsten Bereichen weiter nutzen. 3D Digitale Darstellungen, Visualisierungen,Erstellung von Plänen sind nur einige Beispiele der Nutzung von Punktwolken. Zusammenfassen kann man sagen: Punktwolken sind die 3D und digitale Antwort auf Maßband, Stift und Papier.',
          img: '/img/cloudpic2.jpg',
        },
        {
          titel: 'Punktwolken verwenden',
          desc: 'Eine Punktwolke ist die Basis für eine Vielzahl an Möglichkeiten, um die erfasste Realität weiterzuverarbeiten',
          desc2:
            'Damit lasst sich die Realität Digitalisieren. Die digitalisierten Daten sind auch eine genaue Vermessung der Realität. Diese Anwendungwird bei Sanierungen oder Umbauarbeiten genauso benötigt wie bei Erfassungeiner Immobile zur Weiterverwertung / Vertrieb. 3D Scans und damit Punktwolken kommen auch oft zur Feststellung von Geländestrukturen zur Anwendung(Landschaftsgestaltung, Gärtner). Eine großartige Anwendung ergibt sich auch bei einer Baufortschrittüberwachung. Hier wird der Scan immer mit dem Planstand verglichen. Fehler können somit früher erkannt werden und daher auchv orzeitig behoben werden.',
          list: [
            'Aus Punktwolken werden Bau und Konstruktionspläne abgeleitet.',
            'Die erfassten Daten können zu 3D Modellen verwandelt werden.',
            '3D Modelle sind wiederum die Grundlage für Visualisierungen und Animationen bis hin zu Anwendungen mit VR Systemenund Gamingengines.',
          ],
          img: '/img/Screenshot (6).png',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.txt {
  font-size: 300%;
}
</style>
