<template>
  <div>
    <!-- <v-card class="mx-auto mt-5" max-width="600">
      <v-img
        src="/img/nw_punktwolke_v03.png"
        max-height="800px"
        max-width="500px"
        class="mx-auto"
        contain
      ></v-img>
      <v-card-title class="text-center mx-auto">Punktwolke</v-card-title>
      <div>
        <v-card-text>
          Die Realität zu erfassen und zu digitalisieren, schafft Möglichkeiten,
          die unsere n Visionen keine Grenzen setzt.</v-card-text
        >
      </div>
    </v-card> -->
     <v-img
        src="/img/nw_punktwolke_v03.png"
        max-height="1200px"
        max-width="800px"
        class="mx-auto logo"
        contain
      ></v-img>
       <div>
       <p class="txt mx-auto text-center logo">
          Die Realität zu erfassen und zu digitalisieren, schafft Möglichkeiten,
          die unseren Visionen keine Grenzen setzt.</p>
        
      </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
<style lang="scss" scoped>
.back {
  background-color: #35378D;
}
.txt {
  font-size: 150%;
}
.logo {
  margin-top: 5vh;
}
</style>
